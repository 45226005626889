import {
	type PropsWithChildren,
	createContext,
	useContext,
	useState,
	type Dispatch,
} from 'react'

export const DndContext = createContext({
	data: undefined as any,
	setData: (() => {}) as Dispatch<any>,
})

export const useDnd = () => useContext(DndContext)

export function DndProvider({ children }: PropsWithChildren) {
	const [data, setData] = useState()

	return (
		<DndContext.Provider value={{ data, setData }}>
			{children}
		</DndContext.Provider>
	)
}
